import { t } from '@transifex/native'
import * as R from 'ramda'
import { ActionsObservable, ofType, StateObservable } from 'redux-observable'
import { combineLatest, concat, defer, EMPTY, from, interval, merge, of } from 'rxjs'
import { catchError, debounceTime, delay, map, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators'
import { getUnixTime, subYears } from 'date-fns'

import { AppActions } from '../actions'
import { ImpersonateSuccessAction, LogInSuccessAction } from '../actions/auth'
import { NotificationSocketSuccessAction } from '../actions/notifications'
import { GoToDefaultProfileAction } from '../actions/profiles'
import { RouterLocationChangeAction, RouterSearchDataChangeAction } from '../actions/router'
import {
  FetchStatisticsAction,
  FetchStatisticsCompareAction,
  FetchStatisticsConfirmedAction,
  FetchStatisticsFailureAction,
  FetchStatisticsSuccessAction,
  StatisticsAspectResendAction,
  StatisticsAspectToggleAction,
  StatisticsAspectTryToggleAction,
  StatisticsCountByChangedAction,
  StatisticsExportFailureAction,
  StatisticsExportIsTakingTooLongAction,
  StatisticsExportSuccessAction,
  StatisticsLoadingTakingTooLongAction,
  StatisticsStopStatusChecking,
  StatisticsTagListsAction,
  StatisticsUpdateExportIdAction,
  StatisticsViewDeleteAction,
  StatisticsViewDeleteFailureAction,
  StatisticsViewExportPDFAction,
  StatisticsViewExportPDFFailureAction,
  StatisticsViewExportPPTXAction,
  StatisticsViewExportPPTXFailureAction,
  StatisticsViewExportXLSXAction,
  StatisticsViewExportXLSXFailureAction,
  StatisticsViewSaveAction,
  StatisticsViewSaveFailureAction,
  StatisticsViewSaveSuccessAction,
  StatisticsViewsOpenAction,
} from '../actions/statistics'
import { StatsInfoModalOpenAction } from '../actions/ui'
import { Params } from '../api/opoint-search-suggest.schemas'
import { timeLine } from '../components/statistics/constants'
import {
  getSwitchableMainGraph,
  getWidgetSelection,
  setWidgetSelection,
} from '../components/statistics/statisticsControllers'
import { Profile } from '../components/types/profile'
import { Tag } from '../components/types/tag'
import { multipleTagsHandling } from '../helpers/common'
import { getIsNavigationSectionActive, NavigationSectionKey } from '../helpers/navigation'
import { widgetIds } from '../opoint/common/constants'
import type {
  AspectsRequested,
  SearchItem,
  SearchResult,
  StatisticAspect,
  StatisticsAspectTagLikeEntities,
  TimeRange,
} from '../opoint/flow'
import {
  defaultStatParams,
  parseTimeFilterToTimeStamps,
  searchDataToExpression,
  searchStatistics,
} from '../opoint/search'
import {
  deleteStatisticsView,
  exportPDF,
  exportPPTX,
  exportXLSX,
  getStatisticViewData,
  getStatisticViews,
  reduceSumName,
  saveStatisticView,
} from '../opoint/statistics'
import { getSelectedTagLikeEntities, SPECIAL_ASPECTS_IDS } from '../opoint/statistics/aspects'
import { TEMPLATE_TYPES } from '../opoint/templates'
import { RootState } from '../reducers'
import { getAnalyticsTags } from '../selectors/analyticsSelectors'
import { getProfiles } from '../selectors/profilesSelectors'
import { getMainSearchLine, getSearchTimePeriod, isProfileSelected, isTagSelected } from '../selectors/searchSelectors'
import { getOpointLocale } from '../selectors/settingsSelectors'
import {
  getActiveStatView,
  getActiveViewId,
  getAspectById,
  getAspectCombo,
  getAspects,
  getAspectsRequested,
  getBaskets as getAspectsBaskets,
  getChangedAspectsCountBy,
  getChangedAspectsType,
  getComparePeriod,
  getComparisonModeState,
  getComputedAspectGroup,
  getCorrectStatisticsDates,
  getCountBy,
  getExportFormValues,
  getExportId,
  getIsSelectedArticlesFetch,
  getPreviousPeriodDates,
  getSelectedAspects,
  getStatDocuments,
  getStatFilters,
  getStatisticsCheckedArticles,
  getSubQueries,
} from '../selectors/statisticsSelectors'
import { getBaskets } from '../selectors/tagsComposedSelectors'
import { getTags } from '../selectors/tagsSelectors'
import { getIsStatisticsExportModalOpen } from '../selectors/uiSelectors'

import { SettingsFetchSuccessAction } from '../actions/settings'
import { router } from '../routes'
import { checkReportStatus } from '../opoint/reports'
import { logOutOnExpiredToken, serverIsDown } from './epicsHelper'

const TIMED_REQUESTED_COUNT = 60000
const dayInSeconds = 86400
const DEFAULT_PERIOD = dayInSeconds * 1
const STATISTICS_PAGE = true

type ActionStateType = { state$: StateObservable<RootState> }

const fetchStatisticsConfirmationEpic: (action$: ActionsObservable<AppActions>, { state$ }: ActionStateType) => void = (
  action$: ActionsObservable<AppActions>,
  { state$ }: ActionStateType,
) =>
  action$.pipe(
    ofType<AppActions, FetchStatisticsAction>('FETCH_STATISTICS'),
    switchMap(({ payload }) => {
      const state = state$.value
      const leavingSelectedArticlesSearch = getIsSelectedArticlesFetch(state)

      if (leavingSelectedArticlesSearch) {
        return of<StatsInfoModalOpenAction>({ type: 'STATS_INFO_MODAL_OPEN' })
      }

      return concat(of<FetchStatisticsConfirmedAction>({ type: 'FETCH_STATISTICS_CONFIRMED', payload }))
    }),
  )

const statisticsSearchEpic: (action$: ActionsObservable<AppActions>, { state$ }: ActionStateType) => void = (
  action$: ActionsObservable<AppActions>,
  { state$ }: ActionStateType,
) =>
  combineLatest([
    action$.pipe(ofType<AppActions, FetchStatisticsConfirmedAction>('FETCH_STATISTICS_CONFIRMED')),
    action$.pipe(ofType<AppActions, SettingsFetchSuccessAction>('SETTINGS_FETCH_SUCCESS'), take(1)),
  ]).pipe(
    debounceTime(1000), // Only allowed to be triggered once within this second.
    delay(50),
    switchMap(([{ payload }]) => {
      const { params: customParams, preserveAspects, subqueries } = payload || {}
      const state = state$.value
      let searchline = getMainSearchLine(state)
      const compareMode = getComparisonModeState(state)
      const selectedArticles = getStatisticsCheckedArticles(state)
      const hasSelectedArticles = selectedArticles.length > 0

      if (searchline.filters.length === 0 && searchline.searchterm === '') {
        return EMPTY
      }

      // --- Multiple Tags Handling ---
      if (searchline.filters.filter((filter) => filter.type === 'tag').length > 1) {
        searchline = multipleTagsHandling(searchline)
      }
      // --- Multiple Tags Handling ---

      const requiredSearchItem: SearchItem = {
        linemode: 'R',
        searchline,
      }
      // search params
      // TODO: move to separate function for easy reuse (same as in searchEpics)
      let baskets = { baskets: getBaskets(state) }
      const timeFilter = getSearchTimePeriod(state)
      const counts: Params = {}
      const aspectsRequested: AspectsRequested = {}
      let timePeriod: TimeRange = {}

      if (timeFilter) {
        // if time filter is specified, request more articles than usual 500
        counts.requestedarticles = TIMED_REQUESTED_COUNT
        timePeriod = parseTimeFilterToTimeStamps(timeFilter.id as string, STATISTICS_PAGE)
      } else if (!timeFilter && compareMode) {
        counts.requestedarticles = TIMED_REQUESTED_COUNT
        timePeriod.newest = getUnixTime(new Date())
        timePeriod.oldest = getUnixTime(new Date()) - DEFAULT_PERIOD
      }

      const aspectsRequestedFromState = getAspectsRequested(state)

      // preserve previously selected aspects if any
      if (!R.isEmpty(aspectsRequestedFromState)) {
        aspectsRequested.aspect_requested = aspectsRequestedFromState
      } else {
        aspectsRequested.aspect_requested = []
      }

      let subqueriesFinal = []
      if (aspectsRequested.aspect_requested?.includes(SPECIAL_ASPECTS_IDS.PROFILE)) {
        // @ts-expect-error: Muted so we could enable TS strict mode
        subqueriesFinal = state.search.profileTagIds?.map((id) => ({ id }))
      }

      // @ts-expect-error: Muted so we could enable TS strict mode
      subqueriesFinal = subqueries || subqueriesFinal

      let newComputeGroups
      if (subqueriesFinal.length === 0) {
        const widgetSelection = getWidgetSelection() || '{}'
        const { selectedAspectIds, computeGroups, baskets: tagBaskets } = JSON.parse(widgetSelection)

        newComputeGroups = computeGroups

        if (tagBaskets) {
          baskets = { baskets: tagBaskets }
        }

        if (selectedAspectIds && selectedAspectIds.length > aspectsRequestedFromState.length) {
          aspectsRequested.aspect_requested = selectedAspectIds
        }
      }

      // @ts-expect-error: Muted so we could enable TS strict mode
      aspectsRequested.aspect_requested = R.uniq(aspectsRequested.aspect_requested)

      // @ts-expect-error: Muted so we could enable TS strict mode
      const normalizedCustomParams = R.when(R.propEq('baskets', ''), R.dissoc('baskets'))(customParams || {})

      const finalSearchParams = {
        allmeta: true,
        aspect_info_limit: 1,
        aspect_sep_limit: 0,
        compute_aspects: newComputeGroups
          ? newComputeGroups
          : aspectsRequestedFromState.length === 0
          ? 1
          : getComputedAspectGroup(state),
        subqueries: subqueriesFinal,
        ...aspectsRequested,
        ...timePeriod,
        ...counts,
        ...baskets,
        ...normalizedCustomParams,
      }

      if (hasSelectedArticles) {
        finalSearchParams.articles = selectedArticles
      }

      const search$ = from(searchStatistics([requiredSearchItem], finalSearchParams, {}, getOpointLocale(state))).pipe(
        map((response) => {
          if (response?.searchresult?.errors === 'Solr could not handle the query') {
            return { type: 'FETCH_STATISTICS_FAILURE', payload: response }
          } else {
            return {
              type: 'FETCH_STATISTICS_SUCCESS',
              payload: {
                response,
                preserveAspects,
                subqueries,
                compareMode,
                aspectsRequestedFromState,
                timePeriod,
                regularStatsFetch: true,
              },
            }
          }
        }),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError((error) => of<FetchStatisticsFailureAction>({ type: 'FETCH_STATISTICS_FAILURE', payload: error })),
      )

      const searchTakingTooLong$ = of<StatisticsLoadingTakingTooLongAction>({
        type: 'STATISTICS_LOADING_IS_TAKING_TOO_LONG',
      }).pipe(
        delay(5000), // After 5 seconds, show search is taking too long message
        takeUntil(search$),
        // for filtered articles & returning to statistics
        takeUntil(action$.pipe(ofType<AppActions, FetchStatisticsAction>('FETCH_STATISTICS'))),
      )

      return merge(search$, searchTakingTooLong$).pipe(
        takeUntil(action$.pipe(ofType<AppActions, FetchStatisticsAction>('FETCH_STATISTICS'))),
      )
    }),
  )

const compareStatisticsSearchEpic: (action$: ActionsObservable<AppActions>, { state$ }: ActionStateType) => void = (
  action$: ActionsObservable<AppActions>,
  { state$ }: ActionStateType,
) =>
  action$.pipe(
    ofType<AppActions, FetchStatisticsAction | FetchStatisticsCompareAction>(
      'FETCH_STATISTICS',
      'FETCH_STATISTICS_COMPARE',
    ),
    debounceTime(1000), // Only allowed to be triggered once withing this second.
    delay(1000),
    switchMap((payload) => {
      // @ts-expect-error: Muted so we could enable TS strict mode
      const { params: customParams, preserveAspects, subqueries, statisticsViewSearchDetails } = payload.payload || {}
      const state = state$.value
      let searchline = statisticsViewSearchDetails?.searchline || getMainSearchLine(state)
      const compareMode = getComparisonModeState(state)
      const compareDates = getPreviousPeriodDates(state)
      const period = getComparePeriod(state)

      if (searchline.filters.length === 0 && searchline.searchterm === '') {
        return EMPTY
      }

      if (!compareMode) {
        return EMPTY
      }

      // --- Multiple Tags Handling ---
      if (searchline.filters.filter((filter) => filter.type === 'tag').length > 1) {
        searchline = multipleTagsHandling(searchline)
      }
      // --- Multiple Tags Handling ---

      const requiredSearchItem: SearchItem = {
        linemode: 'R',
        searchline,
      }
      // search params
      // TODO: move to separate function for easy reuse (same as in searchEpics)
      const baskets = { baskets: getBaskets(state) }
      const timeFilter = getSearchTimePeriod(state) || statisticsViewSearchDetails?.timeFilter
      const counts: Params = {}
      const aspectsRequested: AspectsRequested = {}
      let timePeriod: TimeRange = {}

      // @ts-expect-error: Muted so we could enable TS strict mode
      const containsLetters = timeFilter?.id.match(/[a-z]/i)

      const now = new Date()

      // This is for handling previous period, when timeFilter is used
      if (timeFilter) {
        const splittedTimeFilter = (timeFilter.id as string).split('-')

        // This is for handling filter Intervals
        const parsedTimeFilter = parseTimeFilterToTimeStamps(timeFilter.id as string, STATISTICS_PAGE)

        const currentPeriodOldest = containsLetters ? parsedTimeFilter.oldest : parseInt(splittedTimeFilter[0])
        const currentPeriodNewest = containsLetters
          ? parsedTimeFilter.newest || getUnixTime(now)
          : parseInt(splittedTimeFilter[1])

        const lastDay = currentPeriodOldest || getUnixTime(now)
        const firstDay = currentPeriodNewest || getUnixTime(now)
        const daysDiff = firstDay - lastDay

        counts.requestedarticles = TIMED_REQUESTED_COUNT

        let secondPeriodDiff = null
        let periodsDiffs = null

        if (period === 'Custom') {
          // @ts-expect-error: Muted so we could enable TS strict mode
          secondPeriodDiff = +new Date(compareDates.endDate) / 1000 - +new Date(compareDates.startDate) / 1000
          // @ts-expect-error: Muted so we could enable TS strict mode
          periodsDiffs = secondPeriodDiff - daysDiff
        }

        // @ts-expect-error: Muted so we could enable TS strict mode
        if (period === 'Custom' && periodsDiffs > -300 && period === 'Custom' && periodsDiffs < 300) {
          timePeriod.newest = +new Date(compareDates.endDate) / 1000
          timePeriod.oldest = +new Date(compareDates.startDate) / 1000
          const period = `${+new Date(compareDates.startDate) / 1000}-${+new Date(compareDates.endDate) / 1000}`
          timePeriod = parseTimeFilterToTimeStamps(period, STATISTICS_PAGE)
        } else if (period === 'Same period last year') {
          const sameDateLastYearStart = subYears(new Date(lastDay * 1000), 1).toISOString()
          const sameDateLastYearEnd = subYears(new Date(firstDay * 1000), 1).toISOString()
          const period = `${+new Date(sameDateLastYearStart) / 1000}-${+new Date(sameDateLastYearEnd) / 1000}`
          timePeriod = parseTimeFilterToTimeStamps(period, STATISTICS_PAGE)
          // @ts-expect-error: Muted so we could enable TS strict mode
        } else if ((period === 'Custom' && periodsDiffs < -300) || (period === 'Custom' && periodsDiffs > 300)) {
          const period = `${+new Date(compareDates.endDate) / 1000 - daysDiff}-${
            +new Date(compareDates.endDate) / 1000
          }`
          timePeriod = parseTimeFilterToTimeStamps(period, STATISTICS_PAGE)
        } else if (period === 'Previous period') {
          // @ts-expect-error: Muted so we could enable TS strict mode
          const previousPeriod = `${currentPeriodOldest - daysDiff}-${currentPeriodOldest}`
          timePeriod = parseTimeFilterToTimeStamps(previousPeriod, STATISTICS_PAGE)
        }
      } else if (!timeFilter && compareMode) {
        counts.requestedarticles = TIMED_REQUESTED_COUNT

        if (period === 'Custom') {
          timePeriod.newest = +new Date(compareDates.endDate) / 1000
          timePeriod.oldest = +new Date(compareDates.startDate) / 1000
        } else if (period === 'Same period last year') {
          const oldDate = getUnixTime(now) - DEFAULT_PERIOD

          const sameDateLastYearStart = subYears(new Date(oldDate * 1000), 1).toISOString()
          const sameDateLastYearEnd = subYears(now, 1).toISOString()

          const oldest = +new Date(sameDateLastYearStart) / 1000
          const newest = +new Date(sameDateLastYearEnd) / 1000

          timePeriod.newest = parseInt(newest.toFixed(0))
          timePeriod.oldest = parseInt(oldest.toFixed(0))
        } else {
          timePeriod.newest = getUnixTime(now) - DEFAULT_PERIOD
          timePeriod.oldest = getUnixTime(now) - DEFAULT_PERIOD * 2
        }
      }

      // @ts-expect-error: Muted so we could enable TS strict mode
      timePeriod.newest = Math.floor(timePeriod.newest)
      // @ts-expect-error: Muted so we could enable TS strict mode
      timePeriod.oldest = Math.floor(timePeriod.oldest)

      const aspectsRequestedFromState = getAspectsRequested(state)

      // preserve previously selected aspects if any
      if (!R.isEmpty(aspectsRequestedFromState)) {
        aspectsRequested.aspect_requested = aspectsRequestedFromState
      } else {
        aspectsRequested.aspect_requested = []
      }

      let subqueriesFinal = []
      // @ts-expect-error: Muted so we could enable TS strict mode
      if (aspectsRequested.aspect_requested.includes(SPECIAL_ASPECTS_IDS.PROFILE)) {
        // @ts-expect-error: Muted so we could enable TS strict mode
        subqueriesFinal = state.search.profileTagIds?.map((id) => ({ id }))
      }

      subqueriesFinal = subqueries || subqueriesFinal

      let newComputeGroups
      if (subqueriesFinal.length === 0) {
        const widgetSelection = getWidgetSelection() || '{}'
        const { selectedAspectIds, computeGroups } = JSON.parse(widgetSelection)
        newComputeGroups = computeGroups
        if (selectedAspectIds && selectedAspectIds.length > aspectsRequestedFromState.length) {
          aspectsRequested.aspect_requested = selectedAspectIds
        }
      }

      if (statisticsViewSearchDetails?.compute_aspects) {
        newComputeGroups = statisticsViewSearchDetails.compute_aspects
      }

      // @ts-expect-error: Muted so we could enable TS strict mode
      aspectsRequested.aspect_requested = R.uniq(aspectsRequested.aspect_requested)

      /* data from payload */
      const normalizedCustomParams = R.when(R.propEq('baskets', ''), R.dissoc('baskets'))(customParams || {})

      const finalSearchParams = {
        allmeta: true,
        aspect_info_limit: 1,
        aspect_sep_limit: 0,
        compute_aspects: newComputeGroups
          ? newComputeGroups
          : aspectsRequestedFromState.length === 0
          ? 1
          : getComputedAspectGroup(state),
        subqueriesFinal,
        ...aspectsRequested,
        ...timePeriod,
        ...counts,
        ...baskets,
        ...normalizedCustomParams,
      }

      const search$ = concat(
        from(searchStatistics([requiredSearchItem], finalSearchParams, {}, getOpointLocale(state))).pipe(
          map((response) => ({
            type: 'FETCH_STATISTICS_COMPARE_SUCCESS',
            payload: {
              response,
              preserveAspects,
              compareMode,
              aspectsRequestedFromState,
              timePeriod,
            },
          })),
          catchError(logOutOnExpiredToken),
          catchError(serverIsDown),
          catchError((error) => of<FetchStatisticsFailureAction>({ type: 'FETCH_STATISTICS_FAILURE', payload: error })),
        ),
      )

      return search$
    }),
  )

const statisticsAspectsResendEpic = (action$: ActionsObservable<AppActions>, { state$ }: ActionStateType) =>
  action$.pipe(
    ofType<AppActions, StatisticsAspectResendAction>('STATISTICS_ASPECT_RESEND'),
    switchMap(({ payload }) => {
      const { aspect } = payload
      if (aspect.id === widgetIds.tag) {
        const tagLikeEntities = aspect.tagLikeEntities
        // @ts-expect-error: Muted so we could enable TS strict mode
        const selectedBaskets = tagLikeEntities.filter(({ selected }) => selected).map(({ id }) => id)
        const uniqueBaskets = R.uniq(selectedBaskets)
        const stringifiedBaskets = uniqueBaskets.join(',')

        const widgetSelection = getWidgetSelection() || '{}'
        const parsedWidgetSelection = JSON.parse(widgetSelection)
        setWidgetSelection(
          JSON.stringify({
            ...parsedWidgetSelection,
            baskets: stringifiedBaskets,
          }),
        )
      }
      const state = state$.value

      const params: AspectsRequested = {}

      const specialAspectsIds = R.values(SPECIAL_ASPECTS_IDS)

      params.aspect_combo = getAspectCombo(state)

      params.aspect_requested = R.uniq([
        // add previously requested aspects
        ...getAspectsRequested(state),
        // always add profiles, tags, analytics and sentiment
        ...specialAspectsIds,
        // add aspects requested explicitly
        aspect.id,
      ])

      // request to compute this aspect
      // binary OR to also include all preceding aspects
      /* eslint-disable-next-line no-bitwise */
      params.compute_aspects = getComputedAspectGroup(state) | aspect.group

      // add selected profiles to sub-queries
      params.subqueries = getSubQueries(state)
      // and other selected tagLikeEntities (normal, sentiments, analytics, but not profiles)
      // to baskets:
      params.baskets = getAspectsBaskets(state)
      // current aspect is not yet toggled on, so previous params does
      // not contain tagLikeEntities of current aspect, have to add it now:
      switch (aspect.id) {
        case SPECIAL_ASPECTS_IDS.PROFILE:
          // @ts-expect-error: Muted so we could enable TS strict mode
          params.subqueries = params.subqueries.concat(
            getSelectedTagLikeEntities(aspect as { tagLikeEntities: StatisticsAspectTagLikeEntities })?.map(
              ({ id }) => ({ id }),
            ),
          )
          break
        case SPECIAL_ASPECTS_IDS.TAG:
        case SPECIAL_ASPECTS_IDS.SENTIMENT:
        case SPECIAL_ASPECTS_IDS.ANALYSIS:
          params.baskets = R.uniq([
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...(params.baskets ? params.baskets.split(',') : [])?.map((id) => +id),
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...getSelectedTagLikeEntities(aspect as { tagLikeEntities: StatisticsAspectTagLikeEntities })?.map(
              ({ id }) => +id,
            ),
          ]).join(',')
          break
        default:
          break
      }

      return of<StatisticsAspectToggleAction, FetchStatisticsAction>(
        { type: 'STATISTICS_ASPECT_TOGGLE', payload },
        { type: 'FETCH_STATISTICS', payload: { params, preserveAspects: true } },
      )
    }),
  )

const statisticsAspectToggleEpic = (action$: ActionsObservable<AppActions>, { state$ }: ActionStateType) =>
  action$.pipe(
    ofType<AppActions, StatisticsAspectTryToggleAction>('STATISTICS_ASPECT_TRY_TOGGLE'),
    switchMap(({ payload }) => {
      const { aspectId, selected } = payload
      const aspect = getAspectById(aspectId)(state$.value)

      switch (true) {
        // @ts-expect-error: Muted so we could enable TS strict mode
        case aspect.comp_part === -1: // need to compute aspect data
        // @ts-expect-error: Muted so we could enable TS strict mode
        case Object.values(aspect.aspectpart)[0] && Object.values(aspect.aspectpart)[0].names[0] === 'No profile':
          // @ts-expect-error: Muted so we could enable TS strict mode
          payload.aspect = aspect
          // @ts-expect-error: Muted so we could enable TS strict mode
          return of<StatisticsAspectResendAction>({ type: 'STATISTICS_ASPECT_RESEND', payload })

        // @ts-expect-error: Muted so we could enable TS strict mode
        case aspect.selected: // aspect turning off
        // @ts-expect-error: Muted so we could enable TS strict mode
        // eslint-disable-next-line no-fallthrough
        case aspect.comp_part > -1: // turning on but no need to compute additional data
          // @ts-expect-error: Muted so we could enable TS strict mode
          return of<StatisticsAspectToggleAction>({ type: 'STATISTICS_ASPECT_TOGGLE', payload })

        case !aspect:
        // @ts-expect-error: Muted so we could enable TS strict mode
        case aspect.selected === selected: // no change
        default:
          // this should not ever happen
          return of()
      }
    }),
  )

/*
 * Combines multiple action to new action so tags (including special "tags"
 * like profiles analytics etc.) can all be stored to aspects in statistics
 * in one step after all of them are available
 */
const statisticsTagsEpic = (action$: ActionsObservable<AppActions>, { state$ }: ActionStateType) =>
  action$.pipe(
    ofType<AppActions, FetchStatisticsSuccessAction>('FETCH_STATISTICS_SUCCESS'),
    switchMap(({ payload: { subqueries, savedBaskets } }) => {
      const state = state$.value

      const profiles = getProfiles(state)
      const allTags = getTags(state)
      const analysis = getAnalyticsTags(state)

      return of<StatisticsTagListsAction>({
        type: 'STATISTICS_TAG_LISTS',
        payload: {
          allTags: R.map((t: Tag) => R.assoc('selected', isTagSelected(t.id)(state))(t))(allTags),
          analysis: R.map((t: Tag) => R.assoc('selected', isTagSelected(t.id)(state))(t))(analysis),
          profiles: R.map((t: Profile) => R.assoc('selected', isProfileSelected(t.id)(state))(t))(profiles),
          subqueries,
          savedBaskets,
        },
      })
    }),
  )

const fetchStatisticViewsEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, LogInSuccessAction | ImpersonateSuccessAction>('LOG_IN_SUCCESS', 'IMPERSONATE_SUCCESS'),
    switchMap(() =>
      from(getStatisticViews()).pipe(map((data) => ({ type: 'STATISTIC_VIEWS_FETCH_SUCCESS', payload: data }))),
    ),
  )

const fetchStatViewsDetailsEpic = (action$: ActionsObservable<AppActions>, { state$ }: ActionStateType) =>
  action$.pipe(
    ofType<AppActions, StatisticsViewsOpenAction>('STATISTICS_VIEWS_OPEN'),
    switchMap(({ payload: { id } }) => {
      const state = state$.value
      const timeFilter = getSearchTimePeriod(state)
      const compareMode = getComparisonModeState(state)
      const searchLine$ = from(getStatisticViewData(id)).pipe(map((data) => data))

      return searchLine$.pipe(
        takeUntil(action$.pipe(ofType<AppActions, RouterLocationChangeAction>('ROUTER_LOCATION_CHANGE'))),
        switchMap(({ dashboard: { search, chartCollection } }) => {
          const tagWidgetFilters = chartCollection?.find(({ id }) => id === 13)?.filters

          const baskets = {
            baskets: tagWidgetFilters ? tagWidgetFilters : getBaskets(state),
          }

          const searchline = {
            filters: search.filters.filter((filter) => filter.type !== 'timePeriod'),
            searchterm: search.expression,
          }

          const requiredSearchItem = {
            searchline,
            linemode: 'R',
          }

          const counts: Params = {}
          let timePeriod: TimeRange = {}

          if (timeFilter) {
            // if time filter is specified, request more articles than usual 500
            counts.requestedarticles = TIMED_REQUESTED_COUNT
            timePeriod = parseTimeFilterToTimeStamps(timeFilter.id as string, STATISTICS_PAGE)
          } else if (!timeFilter && compareMode) {
            const now = new Date()
            counts.requestedarticles = TIMED_REQUESTED_COUNT
            timePeriod.newest = getUnixTime(now)
            timePeriod.oldest = getUnixTime(now) - DEFAULT_PERIOD * 2
          }

          const params =
            search.params.subqueries?.length > 0
              ? search.params
              : R.merge(search.params, {
                  subqueries: R.compose(
                    R.map((filter: { id: number }) => ({ id: +filter.id })),
                    R.filter(R.propEq('type', 'profile')),
                  )(search.filters),
                })

          const parsedFilters = search.filters
          const expression = search.expression || ''

          return from(
            searchStatistics(
              [requiredSearchItem as SearchItem],
              { ...params, ...baskets, ...counts, ...timePeriod },
              {},
              getOpointLocale(state),
            ),
          ).pipe(
            takeUntil(action$.pipe(ofType<AppActions, RouterLocationChangeAction>('ROUTER_LOCATION_CHANGE'))),
            switchMap((response) => {
              let changedAspectsType
              let changedAspectsCountBy
              const correctAspectsResponse = R.evolve({
                searchresult: {
                  aspectset: {
                    aspect: R.map((aspect: StatisticAspect) => {
                      let isSelected = false
                      chartCollection?.forEach((chart) => {
                        if (chart.id === aspect.id) {
                          isSelected = true

                          // Prefer to use aspect name over chart name as aspect name represents
                          // the correct language
                          if (chart.type !== 'default') {
                            changedAspectsType = {
                              ...changedAspectsType,
                              [aspect.name]: chart.type,
                            }
                          }
                          if (chart.reduceSum && chart.reduceSum.key !== 'default') {
                            changedAspectsCountBy = {
                              ...changedAspectsCountBy,
                              [aspect.name]: chart.reduceSum.name,
                            }
                          } else if (chart.reduceSum && chart.reduceSum.name !== 'n. of articles') {
                            changedAspectsCountBy = {
                              ...changedAspectsCountBy,
                              [aspect.name]: chart.reduceSum.name,
                            }
                          }
                        }
                      })

                      return { ...aspect, selected: isSelected }
                    }),
                  },
                },
              })(response)

              const mainGraphChartCollection = chartCollection.find((chart) => chart.name === timeLine)

              if (compareMode) {
                return of<
                  RouterSearchDataChangeAction,
                  StatisticsCountByChangedAction,
                  FetchStatisticsSuccessAction,
                  FetchStatisticsCompareAction
                >(
                  {
                    type: 'ROUTER_SEARCH_DATA_CHANGE',
                    payload: { parsedFilters, expression },
                  },
                  {
                    type: 'STATISTICS_COUNT_BY_CHANGED',
                    payload: {
                      by: mainGraphChartCollection?.reduceSum?.name ?? 'count',
                    },
                  },
                  {
                    type: 'FETCH_STATISTICS_SUCCESS',
                    payload: {
                      response: correctAspectsResponse as { searchresult: SearchResult },
                      isStatView: true,
                      changedAspectsType,
                      changedAspectsCountBy,
                      subqueries: search.params.subqueries,
                      compareMode,
                      timePeriod,
                      savedBaskets: tagWidgetFilters,
                    },
                  },
                  {
                    type: 'FETCH_STATISTICS_COMPARE',
                    payload: {
                      statisticsViewSearchDetails: { compute_aspects: params.compute_aspects, searchline, timeFilter },
                    },
                  },
                )
              } else {
                return concat(
                  of<RouterSearchDataChangeAction, StatisticsCountByChangedAction, FetchStatisticsSuccessAction>(
                    {
                      type: 'ROUTER_SEARCH_DATA_CHANGE',
                      payload: { parsedFilters, expression },
                    },
                    {
                      type: 'STATISTICS_COUNT_BY_CHANGED',
                      payload: {
                        by: mainGraphChartCollection?.reduceSum?.name ?? 'count',
                      },
                    },
                    {
                      type: 'FETCH_STATISTICS_SUCCESS',
                      payload: {
                        response: correctAspectsResponse as { searchresult: SearchResult },
                        isStatView: true,
                        changedAspectsType,
                        changedAspectsCountBy,
                        subqueries: search.params.subqueries,
                        compareMode,
                        timePeriod,
                        savedBaskets: tagWidgetFilters,
                      },
                    },
                  ),
                )
              }
            }),
            catchError(logOutOnExpiredToken),
            catchError(serverIsDown),
            catchError((error) =>
              of<FetchStatisticsFailureAction>({ type: 'FETCH_STATISTICS_FAILURE', payload: error }),
            ),
          )
        }),
        catchError((error) => of<FetchStatisticsFailureAction>({ type: 'FETCH_STATISTICS_FAILURE', payload: error })),
      )
    }),
  )

export const deleteStatViewEpic = (action$: ActionsObservable<AppActions>, { state$ }: ActionStateType) =>
  action$.pipe(
    ofType<AppActions, StatisticsViewDeleteAction>('STATISTICS_VIEW_DELETE'),
    switchMap(() => {
      const deletedView = getActiveViewId(state$.value)

      const deleteView$ = from(deleteStatisticsView(deletedView)).pipe(
        map(() => ({ type: 'STATISTICS_VIEW_DELETE_SUCCESS', payload: { id: deletedView } })),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<StatisticsViewDeleteFailureAction>({ type: 'STATISTICS_VIEW_DELETE_FAILURE' })),
      )
      const goToHomepage$ = defer(getStatisticViews).pipe(
        switchMap(() => of<GoToDefaultProfileAction>({ type: 'GO_TO_DEFAULT_PROFILE' })),
      )

      return deleteView$.pipe(switchMap((deleteAlertAction) => concat(of(deleteAlertAction), goToHomepage$)))
    }),
  )

export const saveStatViewEpic = (action$: ActionsObservable<AppActions>, { state$ }: ActionStateType) =>
  action$.pipe(
    ofType<AppActions, StatisticsViewSaveAction>('STATISTICS_VIEW_SAVE'),
    switchMap(({ payload: { name, folder } }) => {
      const state = state$.value
      const aspects = getAspects(state)
      const id = getActiveViewId(state)
      const color = 'blue'
      const selectedAspects = getSelectedAspects(state)
      const countBy = getCountBy(state)
      const changedAspectsType = getChangedAspectsType(state)
      const changedAspectsCountBy = getChangedAspectsCountBy(state)
      const reduceSumDefault = {
        key: 'default',
        name: reduceSumName[countBy],
      }
      const timeFilter = getSearchTimePeriod(state)
      const counts: Params = {}
      let timePeriod: TimeRange = {}
      const subqueries = getSubQueries(state)
      const compareMode = getComparisonModeState(state)

      if (timeFilter) {
        // if time filter is specified, request more articles than usual 30
        counts.requestedarticles = TIMED_REQUESTED_COUNT
        timePeriod = parseTimeFilterToTimeStamps(timeFilter.id as string, STATISTICS_PAGE)
      }

      const tagWidgetAspect = aspects?.find(({ id }) => id === widgetIds.tag)
      const selectedTags = tagWidgetAspect?.tagLikeEntities?.filter(({ selected }) => selected)
      const stringifiedSelectedTags = selectedTags?.map(({ id }) => id).join(',')

      const params = {
        ...defaultStatParams,
        acceptedcacheage: -1,
        aspect_info_limit: 1,
        aspect_sep_limit: 0,
        compute_aspects: getComputedAspectGroup(state),
        aspect_requested: getAspectsRequested(state),
        baskets: getBaskets(state),
        watch_id: 0,
        subqueries,
        ...timePeriod,
        ...counts,
      }
      const searchline = getMainSearchLine(state)

      const mainGraph = {
        id: 99,
        name: timeLine,
        reduceSum: {
          key: reduceSumName[countBy],
          name: reduceSumName[countBy],
        },
        isArrayAspect: false,
        filters: null,
        type: 'default',
      }

      const correctPayload = {
        color,
        name,
        folder,
        dashboard: {
          chartCollection: [
            ...R.map((aspect) => {
              const aspectData = R.pick(['id', 'name'], aspect)
              const customCountBy = changedAspectsCountBy[aspect.name]
              const filters = aspect.id === widgetIds.tag && !!stringifiedSelectedTags ? stringifiedSelectedTags : null
              const reduceSum =
                customCountBy && customCountBy !== 'count'
                  ? {
                      key: reduceSumName[customCountBy],
                      name: reduceSumName[customCountBy],
                    }
                  : reduceSumDefault
              const chartData = {
                reduceSum,
                ...aspectData,
                isArrayAspect: false,
                type: changedAspectsType[aspect.name] || 'default',
                filters,
              }

              return chartData
            }, selectedAspects),
            mainGraph,
          ],
          chartLanguage: getOpointLocale(state),
          search: {
            expression: searchline.searchterm,
            filters: searchline.filters,
            params,
          },
        },
      }

      const saveView$ = from(saveStatisticView(correctPayload, id)).pipe(
        switchMap(({ id }) => {
          const activeSavedStatistics = getIsNavigationSectionActive(NavigationSectionKey.SAVED_STATISTICS)

          if (activeSavedStatistics) {
            return of<StatisticsViewSaveSuccessAction>({ type: 'STATISTICS_VIEW_SAVE_SUCCESS' })
          } else {
            const newPath = `/statistics/${compareMode ? `${id}/compare` : `${id}`}`
            return concat(
              of<StatisticsViewSaveSuccessAction>({ type: 'STATISTICS_VIEW_SAVE_SUCCESS' }),
              of(newPath).pipe(
                tap(() => router.navigate(newPath)),
                map(() => ({ type: 'ROUTER_LOCATION_CHANGE' })),
              ),
            )
          }
        }),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<StatisticsViewSaveFailureAction>({ type: 'STATISTICS_VIEW_SAVE_FAILURE' })),
      )

      return saveView$.pipe(
        switchMap((saveViewAction) =>
          concat(
            of(saveViewAction),
            from(getStatisticViews()).pipe(map((data) => ({ type: 'STATISTIC_VIEWS_FETCH_SUCCESS', payload: data }))),
          ),
        ),
      )
    }),
  )

const exportStatisticsAsPDF = (action$: ActionsObservable<AppActions>, { state$ }: ActionStateType) =>
  action$.pipe(
    ofType<AppActions, StatisticsViewExportPDFAction>('STATISTICS_VIEW_EXPORT_PDF'),
    switchMap(({ payload: { SVGs, metaString, type, periodChosen } }) => {
      const state = state$.value
      const exportAsPdfModalFormValues = getExportFormValues(state)

      // If exporting statistics, without filling "title", its placeholder will be filled.
      if (!exportAsPdfModalFormValues.title) {
        exportAsPdfModalFormValues.title = exportAsPdfModalFormValues.placeholderText.title
      }

      const statsView = getActiveStatView(state)
      const activeStatisticsViewName = statsView && statsView.name

      return from(
        exportPDF({
          activeStatisticsViewName,
          metaString,
          SVGs,
          type,
          periodChosen,
          ...exportAsPdfModalFormValues,
        }),
      ).pipe(
        map(({ exportId }) => ({ type: 'UPDATE_STATISTICS_EXPORT_ID', payload: { exportId } })),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() =>
          of<StatisticsViewExportPDFFailureAction>({
            type: 'STATISTICS_VIEW_EXPORT_PDF_FAILURE',
          }),
        ),
      )
    }),
  )

const exportStatisticsAsPPTX = (action$: ActionsObservable<AppActions>, { state$ }: ActionStateType) =>
  action$.pipe(
    ofType<AppActions, StatisticsViewExportPPTXAction>('STATISTICS_VIEW_EXPORT_PPTX'),
    switchMap(({ payload: { SVGs, metaString, type, periodChosen } }) => {
      const state = state$.value

      const exportAsPptxModalFormValues = getExportFormValues(state)

      // If exporting statistics, without filling "title", its placeholder will be filled.
      if (!exportAsPptxModalFormValues.title) {
        exportAsPptxModalFormValues.title = exportAsPptxModalFormValues.placeholderText.title
      }

      const statsView = getActiveStatView(state)
      const activeStatisticsViewName = statsView && statsView.name

      return from(
        exportPPTX({
          activeStatisticsViewName,
          metaString,
          SVGs,
          type,
          periodChosen,
          ...exportAsPptxModalFormValues,
        }),
      ).pipe(
        map(({ exportId }) => ({ type: 'UPDATE_STATISTICS_EXPORT_ID', payload: { exportId } })),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() =>
          of<StatisticsViewExportPPTXFailureAction>({
            type: 'STATISTICS_VIEW_EXPORT_PPTX_FAILURE',
          }),
        ),
      )
    }),
  )

const exportStatisticsAsXLSX = (action$: ActionsObservable<AppActions>, { state$ }: ActionStateType) =>
  action$.pipe(
    ofType<AppActions, StatisticsViewExportXLSXAction>('STATISTICS_VIEW_EXPORT_XLSX'),
    switchMap(({ payload: { metaString, type, periodChosen } }) => {
      const state = state$.value

      const exportAsXlsxModalFormValues = getExportFormValues(state)

      // If exporting statistics, without filling "title", its placeholder will be filled.
      if (!exportAsXlsxModalFormValues.title) {
        exportAsXlsxModalFormValues.title = exportAsXlsxModalFormValues.placeholderText.title
      }

      const statsView = getActiveStatView(state)
      const activeStatisticsViewName = statsView && statsView.name

      const selectedAspects = getSelectedAspects(state)
      const changedAspectsType = getChangedAspectsType(state)
      const changedAspectsCountBy = getChangedAspectsCountBy(state)
      const countBy = getCountBy(state)
      const topChartType = getSwitchableMainGraph()

      // Clean aspects type so not repeated
      const charts = selectedAspects.map((aspect) => ({
        name: aspect.name,
        id: aspect.id,
        type: changedAspectsType[aspect.name],
        countBy: changedAspectsCountBy[aspect.name],
      }))

      const aspectSet = getAspects(state)
      const documents = getStatDocuments(state)

      const { correctStartDate: startTime, correctEndDate: endTime } = getCorrectStatisticsDates(state)

      const filters = getStatFilters(state)
      const timeFilter = filters.time

      const [selectedStartTime, selectedEndTime] = timeFilter
        ?.find((f) => f.filterType === 'RangedFilter')
        ?.reduce((acc, red) => [...acc, red[0]]) ?? [startTime, endTime]

      const timeZone = new Date().getTimezoneOffset()

      const expressions = R.compose(searchDataToExpression, getMainSearchLine)(state)

      const exportXLSX$ = defer(() =>
        exportXLSX({
          activeStatisticsViewName,
          metaString,
          charts,
          aspectSet,
          documents,
          countBy,
          topChartType,
          type,
          periodChosen,
          startTime,
          endTime,
          selectedStartTime,
          selectedEndTime,
          timeZone,
          expressions,
          ...exportAsXlsxModalFormValues,
        }),
      )

      return exportXLSX$.pipe(
        map(({ exportId }) => ({ type: 'UPDATE_STATISTICS_EXPORT_ID', payload: { exportId } })),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() =>
          of<StatisticsViewExportXLSXFailureAction>({
            type: 'STATISTICS_VIEW_EXPORT_XLSX_FAILURE',
          }),
        ),
      )
    }),
  )

const exportCreateFinishEpic = (action$: ActionsObservable<AppActions>, { state$ }: ActionStateType) =>
  action$.pipe(
    delay(1000), // Delayed 1 second to allow the exportId to be saved in the state.
    ofType<AppActions, NotificationSocketSuccessAction>('NOTIFICATIONS_SOCKET_SUCCESS'),
    switchMap(({ payload }) => {
      const state = state$.value
      const exportId = getExportId(state)
      const isExportModalOpen = getIsStatisticsExportModalOpen(state)
      const exportObject = payload.object?.value
      const isNotificationUpdate = payload.type === 'updated'
      const isExportNotification = payload.object?.type === 'export'
      const exportType = Object.keys(TEMPLATE_TYPES).find((key) => TEMPLATE_TYPES[key] === exportObject.type)
      const isCurrentTab = exportObject?.id === exportId

      const displayedExportType = exportType === 'XLS' ? 'Excel' : exportType
      const errorMessage = t(
        'An error occurred while generating your statistics {exportType} file. Please try again.',
        {
          exportType: displayedExportType,
        },
      )

      // Only XLSX export, returns an exportId.
      if (!isExportNotification || !isNotificationUpdate || (exportType === 'XLS' && !isCurrentTab)) {
        return of()
      }

      if (exportObject.status === -1) {
        return of<StatisticsExportFailureAction>({
          type: 'STATISTICS_EXPORT_FAILURE',
          payload: { error: errorMessage },
        })
      }

      return of<StatisticsExportSuccessAction>({
        type: 'STATISTICS_EXPORT_SUCCESS',
        // If the export modal is closed, we don't want to pass the exportObject to the reducer.
        // As it will be saved in the state, and shown when the modal is opened.
        // But we still want to trigger the action, to stop the loading.
        payload: {
          exportObject: isExportModalOpen ? exportObject : null,
          message: t('Your statistics {exportType} file, was successfully generated', {
            exportType: displayedExportType,
          }),
        },
      })
    }),
  )

const exportTakingTooLongEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, StatisticsViewExportPDFAction | StatisticsViewExportPPTXAction | StatisticsViewExportXLSXAction>(
      'STATISTICS_VIEW_EXPORT_XLSX',
      'STATISTICS_VIEW_EXPORT_PPTX',
      'STATISTICS_VIEW_EXPORT_PDF',
    ),

    switchMap(() => {
      return of<StatisticsExportIsTakingTooLongAction>({ type: 'STATISTICS_EXPORT_IS_TAKING_TOO_LONG' }).pipe(
        delay(5000), // After 5 seconds, show export is taking too long message
        takeUntil(action$.pipe(ofType<AppActions, StatisticsExportSuccessAction>('STATISTICS_EXPORT_SUCCESS'))),
      )
    }),
  )

const exportCheckingStatusEpic = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<AppActions, StatisticsUpdateExportIdAction>('UPDATE_STATISTICS_EXPORT_ID'),
    switchMap(({ payload }) => {
      const checkStatus$ = defer(() => checkReportStatus({ id: payload.exportId }))

      return interval(5000).pipe(
        withLatestFrom(state$),
        switchMap(() => {
          const isExportModalOpen = getIsStatisticsExportModalOpen(state$.value)

          if (!isExportModalOpen) {
            return of<StatisticsStopStatusChecking>({ type: 'STATISTICS_STOP_STATUS_CHECKING' })
          }

          return checkStatus$.pipe(
            switchMap((exportObject) => {
              const exportType = Object.keys(TEMPLATE_TYPES).find((key) => TEMPLATE_TYPES[key] === exportObject.type)
              const displayedExportType = exportType === 'XLS' ? 'Excel' : exportType

              const errorMessage = t(
                'An error occurred while generating your statistics {exportType} file. Please try again.',
                {
                  exportType: displayedExportType,
                },
              )

              if (exportObject.status === 1) {
                return of<StatisticsExportSuccessAction>({
                  type: 'STATISTICS_EXPORT_SUCCESS',
                  payload: {
                    exportObject: isExportModalOpen ? exportObject : null,
                    message: t('Your statistics {exportType} file, was successfully generated', {
                      exportType: displayedExportType,
                    }),
                  },
                })
              } else if (exportObject.status === -1) {
                return of<StatisticsExportFailureAction>({
                  type: 'STATISTICS_EXPORT_FAILURE',
                  payload: {
                    error: errorMessage,
                  },
                })
              } else {
                return of()
              }
            }),
          )
        }),
        takeUntil(
          action$.pipe(
            ofType<
              AppActions,
              StatisticsExportSuccessAction | StatisticsExportFailureAction | StatisticsStopStatusChecking
            >('STATISTICS_EXPORT_SUCCESS', 'STATISTICS_EXPORT_FAILURE', 'STATISTICS_STOP_STATUS_CHECKING'),
          ),
        ),
      )
    }),
  )

export default [
  // showStatisticsEpic,
  fetchStatisticsConfirmationEpic,
  compareStatisticsSearchEpic,
  deleteStatViewEpic,
  exportStatisticsAsPDF,
  exportStatisticsAsPPTX,
  exportStatisticsAsXLSX,
  fetchStatisticViewsEpic,
  fetchStatViewsDetailsEpic,
  saveStatViewEpic,
  statisticsAspectsResendEpic,
  statisticsAspectToggleEpic,
  statisticsSearchEpic,
  statisticsTagsEpic,
  exportCreateFinishEpic,
  exportTakingTooLongEpic,
  exportCheckingStatusEpic,
]
